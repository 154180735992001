<script setup lang="ts">
import { useEmployerStore } from "@/store/employer";
import { useCompanyStore } from "@/store/company";
import IntroStep from "./steps/IntroStep.vue";
import StepOne from "./steps/StepOne.vue";
import StepTwo from "./steps/StepTwo.vue";
import StepThree from "./steps/StepThree.vue";
import StepFour from "./steps/StepFour.vue";

const employerStore = useEmployerStore();
const companyStore = useCompanyStore();
const { employer } = storeToRefs(employerStore);
const { company } = storeToRefs(companyStore);
const show = ref(true);
const currentStepNumber = ref(0);
const claimed = ref(false);

const steps = reactive([
  {
    component: markRaw(StepOne),
    title: "Company Information",
    desc: "The first thing you should do is fill out your company information.",
  },
  {
    component: markRaw(StepTwo),
    title: "Billing Information",
    desc: "In order to purchase Job Credits to create Job Posts, you need to fill out your billing information.",
  },
  {
    component: markRaw(StepThree),
    title: "Purchase Job Credits",
    desc: "You can then purchase Job Credits by navigating to the Payments page.",
    hideNextButton: !company.value!.promo_claimed,
  },
  {
    component: markRaw(StepFour),
    title: "Create Job Post",
    desc: "Finally, after purchasing Job Credits, you can create your first Job Post by navigating to the Jobs page.",
  },
]);

const currentStep = computed(() => steps[currentStepNumber.value - 1]);
const isLastStep = computed(() => currentStepNumber.value === steps.length);

const handleNextStep = async () => {
  if (!isLastStep.value) return (currentStepNumber.value += 1);
  try {
    if (!employer.value) throw Error(ERROR_MESSAGES.employer_not_found);
    employer.value.onboarding_seen = true;
    const { error } = await employerStore.updateEmployer({ employerData: { onboarding_seen: true } });
    if (error) throw error;
  } catch (e: any) {
    showNotifyError(e.message ?? ERROR_MESSAGES.error_updating_profile);
  }
};

const prevStep = () => {
  currentStepNumber.value -= 1;
};

const creditClaimed = () => {
  claimed.value = true;
  steps[currentStepNumber.value - 1].hideNextButton = false;
};
</script>

<template>
  <div v-if="employer">
    <DialogModal
      :show="!employer.onboarding_seen"
      @close="show = false"
      :closeable="false"
      max-width="w-full sm:max-w-3xl"
      footer-justify="justify-between"
    >
      <template #title v-if="!currentStep">
        <p>Welcome to {{ $config.public.appName }}!</p>
        <p class="font-normal text-sm">Let's get you started with the basics.</p>
      </template>
      <template v-else #title>
        <p>{{ currentStep!.title }}</p>
        <p class="font-normal text-sm">{{ currentStep!.desc }}</p>
      </template>
      <template #content>
        <div class="bg-light-gray z-10 rounded-lg w-full h-[135px] md:h-[388px] lg:h-[406px] overflow-hidden">
          <transition name="slide-fade" mode="out-in">
            <IntroStep v-if="currentStepNumber === 0" @next-step="handleNextStep" />
            <component v-else :is="currentStep.component" @next-step="handleNextStep" @claimed="creditClaimed" :claimed="claimed" />
          </transition>
        </div>
        <div class="grid grid-cols-3 items-center mt-3 bg-white-gray p-2 rounded-lg h-[44px]">
          <template v-if="currentStepNumber > 0">
            <OutlineButton @click="prevStep" class="w-fit"> Prev </OutlineButton>
            <p class="font-semibold text-xs text-center">{{ currentStepNumber }} / {{ steps.length }}</p>
            <PrimaryButton v-if="!currentStep?.hideNextButton" @click="handleNextStep" class="w-fit justify-self-end">
              {{ isLastStep ? "Finish" : "Next" }}
            </PrimaryButton>
          </template>
        </div>
      </template>
    </DialogModal>
  </div>
</template>
